import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface useDocumentTitleOutput {
  setDocumentTitle: Dispatch<SetStateAction<string | undefined>>;
}

const NAME = 'ScreenHits TV';

const useDocumentTitle = (title?: string | undefined): useDocumentTitleOutput => {
  const { t } = useTranslation();
  const [documentTitle, setDocumentTitle] = useState(title);

  useEffect(() => {
    const prevTitle = document.title;
    document.title = documentTitle ? `${t(documentTitle)} - ${NAME}` : NAME;

    return () => {
      document.title = prevTitle;
    };
  }, [documentTitle, t]);

  return { setDocumentTitle };
};

export default useDocumentTitle;
