import React, { useEffect } from 'react';
import { Box, Portal, Typography } from '@mui/material';
import PinField from 'react-pin-field';
import colors from 'theme/colors';
import { hideOverflow, showOverflow } from 'helpers/Common';
import useAxiosRequest from 'hooks/useApiRequest';
import { checkParentControlPassword } from 'api/authApi';

interface Props {
  isShow?: boolean;
  setIsShow: () => void;
}

const ParentControl: React.FC<Props> = ({ isShow, setIsShow }: Props): JSX.Element => {
  const { requestFunc, setIsError, isError } = useAxiosRequest();

  const handleCheckProtectionPassword = async (pin: string) => {
    console.log(pin);

    const res = await requestFunc(checkParentControlPassword(pin));
    if (res) {
      setIsShow();
    }
  };

  useEffect(() => {
    if (isShow) {
      hideOverflow();
    }
    return () => {
      showOverflow();
    };
  }, [isShow]);

  useEffect(() => {
    if (isError) {
      setIsError(false);
    }
  }, [setIsError, isError]);

  return (
    <>
      {isShow && (
        <Portal>
          <Box
            component='div'
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              height: '100vh',
              zIndex: 1900,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'none',
              backdropFilter: 'blur(5px)',
              mask: 'linear-gradient(to left, rgba(0, 0, 0, 0), #000 -3%)',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography
                variant='h5'
                sx={{
                  color: isError ? colors.error : 'text.primary',
                  fontWeight: 700,
                }}
              >
                Child Protection
              </Typography>

              <Typography
                variant='body1'
                sx={{
                  color: isError ? colors.error : 'text.primary',
                  my: 2,
                }}
              >
                {isError
                  ? 'Wrong password! Please enter new password!'
                  : 'Enter the set password for Child Protection'}
              </Typography>

              <Box
                sx={{
                  position: 'relative',
                  zIndex: 4,
                  '& input[type="password"]': {
                    width: 40,
                    height: 40,
                    fontSize: 16,
                    color: colors.white,
                    textAlign: 'center',
                    backgroundColor: 'transparent',
                    borderWidth: 2,
                    borderStyle: 'solid',
                    borderColor: isError ? colors.error : colors.white,
                    borderRadius: 2,
                    outline: 'none',
                    '&:not(last-child)': {
                      mr: 2,
                    },
                    '&:focus': {
                      cursor: 'pointer',
                      borderColor: isError ? colors.error : colors.white,
                    },
                  },
                }}
              >
                <PinField
                  id='child-pin'
                  name='child-pin'
                  type='password'
                  autoComplete='new-password'
                  length={4}
                  autoFocus
                  validate={/^[0-9]$/}
                  onComplete={(pin) => handleCheckProtectionPassword(pin)}
                />
              </Box>
            </Box>
          </Box>
        </Portal>
      )}
    </>
  );
};

export default ParentControl;
