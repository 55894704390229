import axios, { AxiosInstance } from 'axios';
import { VOUCHER_TYPES } from 'theme/const';

const apiClient: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
  },
});

const setAuthorizationHeader = (token: string): void => {
  apiClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

const deleteAuthorizationHeader = () => {
  delete apiClient.defaults.headers.common['Authorization'];
};

const setDeviceType = (type: string): void => {
  apiClient.defaults.headers.common['Device-Type'] = type;
};

const setApiLanguageHeader = (language: string): void => {
  apiClient.defaults.headers.common['language'] = language;
};

const setQrpSessionHeader = (key: string, session_id: string) => {
  apiClient.defaults.headers.common[key] = session_id;
};

const setQrfSessionHeader = (type: VOUCHER_TYPES,) => {
  apiClient.defaults.headers.common['qrf'] = type;
};

const getAuthorizationHeader = () => apiClient.defaults.headers.common['Authorization'];

export {
  apiClient,
  setAuthorizationHeader,
  setDeviceType,
  setApiLanguageHeader,
  setQrpSessionHeader,
  setQrfSessionHeader,
  getAuthorizationHeader,
  deleteAuthorizationHeader,
};
