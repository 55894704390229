import { useCallback, useEffect, useState } from 'react';
import { useAppContext } from 'store';
import { BackgroundType, PageType } from 'types/Misc';

interface usePageBackgroundOutput {
  backgroundURL: string | undefined;
}

const usePageBackground = (
  backgroundType?: BackgroundType,
  pageType?: PageType,
): usePageBackgroundOutput => {
  const {
    state: { backgrounds },
  } = useAppContext();

  const [backgroundURL, setBackgroundURL] = useState<string | undefined>('');

  const handleSetBackground = useCallback(() => {
    const b = backgrounds.find((item) => item.type === backgroundType && item.page === pageType);
    setBackgroundURL(b?.url);
  }, [backgrounds, backgroundType, pageType]);

  useEffect(() => {
    handleSetBackground();
  }, [handleSetBackground]);

  return { backgroundURL };
};

export default usePageBackground;
