import { useEffect } from 'react';
import { Box, Dialog, Grid, IconButton, Stack, Typography } from '@mui/material';
import { getLandingMedia, landingPageApiUrl } from 'api/landingPageApi';
import WatchITCarousel from 'components/Carousel/WatchITCarousel';
import useDocumentTitle from 'hooks/useDocumentTitle';
import { useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';

import { setCastLimit } from 'helpers/Strings';

import colors from 'theme/colors';
import XMarkIcon from 'components/icons/XMarkIcon';
import { useTranslation } from 'react-i18next';

const LandingMedia: React.FC = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { media_id } = useParams();
  const { setDocumentTitle } = useDocumentTitle();
  const { data: media } = useSWR(
    () => landingPageApiUrl.landingPageMedia(media_id || '0'),
    () => getLandingMedia(media_id),
    {
      revalidateOnMount: true,
      suspense: false,
    },
  );

  const handleOnCloseClick = (): void => {
    navigate(-1);
  };

  useEffect(() => {
    setDocumentTitle(`${t('pages.whereToWatch')}: ${media?.title}`);
  }, [t, setDocumentTitle, media?.title]);

  return (
    <Dialog
      open={(media && media?.id > 0) || false}
      maxWidth={false}
      sx={{
        width: 1,
        maxWidth: { xs: 0.7, lg: 1 },
      }}
    >
      <Box
        sx={{
          position: 'relative',
          width: 1,
          height: '100vh',
          backgroundImage: `url(${media?.thumbnailLandscape})`,
          backgroundSize: 'cover',
          '& p, h2, h3': {
            fontFamily: 'Montserrat',
          },
        }}
      >
        <Box
          sx={{
            position: 'relative',
            width: 1,
            height: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            background: colors.landingHeader,
            p: { xs: 0, lg: 5 },
          }}
        >
          <IconButton
            sx={{
              position: 'absolute',
              top: (theme) => theme.spacing(1),
              right: (theme) => theme.spacing(1),
              color: colors.landingDarkText,
              zIndex: (theme) => theme.zIndex.modal + 1,
            }}
            onClick={handleOnCloseClick}
          >
            <XMarkIcon />
          </IconButton>

          <Stack
            display='flex'
            flexDirection='row'
            flexWrap='wrap'
            justifyContent='center'
            alignItems='center'
          >
            <Typography
              variant='h2'
              textTransform='uppercase'
              textAlign='center'
              fontWeight='normal'
              letterSpacing={5.2}
              sx={{
                my: 2,
              }}
            >
              {t('landing.universalSearch.mediaPopup.title')}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={5}>
                <Stack
                  spacing={1}
                  sx={{
                    px: { xs: 2, lg: 0 },
                    '& .media-info': {
                      display: { xs: 'none', md: 'block' },
                      color: colors.landingDarkText,
                      mb: 1,
                    },
                  }}
                >
                  {media?.titleTreatment ? (
                    <Box
                      sx={{
                        display: 'flex',
                        width: 1,
                        height: { xs: 60, lg: 120 },
                      }}
                    >
                      <Box
                        component='img'
                        src={media?.titleTreatment}
                        alt={media?.title}
                        sx={{
                          width: 1,
                          height: 'auto',
                          objectFit: 'contain',
                        }}
                      />
                    </Box>
                  ) : (
                    <Typography variant='h3' fontWeight='600' fontSize={32}>
                      {media?.title}
                    </Typography>
                  )}
                  <Box
                    sx={{
                      width: 1,
                      '& p': {
                        fontSize: { xs: 14, sm: 18, md: 20 },
                      },
                    }}
                  >
                    <Typography>{media?.displayYear}</Typography>
                  </Box>
                  <Typography
                    fontSize={{ xs: 14, sm: 18, md: 20 }}
                    sx={{
                      width: 1,
                      p: { xs: 2 },
                    }}
                  >
                    {media?.logline}
                  </Typography>
                  {media?.directors && (
                    <Typography className='media-info'>
                      {t('landing.universalSearch.item.directedBy')}: {media?.directors}
                    </Typography>
                  )}
                  {media?.cast && (
                    <Typography className='media-info'>
                      {t('landing.universalSearch.item.cast')}: {setCastLimit(media?.cast, 3)}
                    </Typography>
                  )}
                  {media?.displayGenres && (
                    <Typography className='media-info'>
                      {t('landing.universalSearch.item.genre')}: {media?.displayGenres}
                    </Typography>
                  )}
                </Stack>
              </Grid>
              <Grid
                item
                xs={12}
                md={7}
                display='flex'
                flexDirection='column'
                justifyContent='center'
                alignItems='center'
              >
                <WatchITCarousel channels={media?.channels} />
              </Grid>
            </Grid>
          </Stack>
        </Box>
      </Box>
    </Dialog>
  );
};

export default LandingMedia;
