import { numberToAmPm } from './DataTime';

const setSeasonAndEpisode = (index: string | number, order: number | null): string => {
  const episode = order != null && order >= 10 ? order : `0${order}`;
  return `S${index}E${episode}`;
};

const setCastLimit = (cast: string | undefined, limit: number): string => {
  const castLimit = cast?.split(',').filter((_, index) => index < limit);
  return `${castLimit?.join(', ')}...`;
};

const setHourLabel = (hour: number) => {
  return numberToAmPm(hour);
};

const getWordCount = (str: string) => {
  let count = 0;
  if (str !== null) {
    count = str.split(' ').length;
  }
  return count;
};

const cutByWordCount = (text: string) => {
  return text.replace(/(([^\s]+\s+){25}).+/, '$1...');
};

const bundlePrice = (price: string) => {
  const p = price.split(' ');
  return `<h2>${p[0]}</h2> <h3>${p[2].charAt(0).toUpperCase() + p[2].slice(1)}</h3>`;
};

export {
  setSeasonAndEpisode,
  setCastLimit,
  setHourLabel,
  getWordCount,
  cutByWordCount,
  bundlePrice,
};
