import useAuth from 'hooks/useAuth';
import useJWT, { Token } from 'hooks/useJWT';
import jwtDecode from 'jwt-decode';
import { useEffect } from 'react';

interface Props {
  children: React.ReactNode;
}

const SetCurrentProfile: React.FC<Props> = ({ children }: Props): JSX.Element => {
  const { setUserProfile } = useAuth();
  const { accessToken } = useJWT();

  useEffect(() => {
    const decode = accessToken && jwtDecode<Token>(accessToken);
    decode && setUserProfile(decode?.profile);
    // eslint-disable-next-line
  }, []);

  return <>{children}</>;
};

export default SetCurrentProfile;
