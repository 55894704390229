import { Box } from '@mui/material';
import { MenuItems } from 'types/MenuItems';
import NavigationLink from './NavigationLink';

interface Props {
  drawerLinks: MenuItems | undefined;
  onToggle: () => void;
}

const NavigationLinks: React.FC<Props> = ({ drawerLinks, onToggle }: Props): JSX.Element => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
      onClick={onToggle}
      onKeyDown={onToggle}
    >
      {drawerLinks?.map((link) => link.isVisible && <NavigationLink key={link.uid} link={link} />)}
    </Box>
  );
};

export default NavigationLinks;
