import { CurrentProfile } from 'types/Profile';

const STORAGE = {
  SCH_TV_PROFILE: 'SCH_TV_PROFILE',
};

const setStorageProfileItem = (profile: CurrentProfile) =>
  localStorage.setItem(STORAGE.SCH_TV_PROFILE, JSON.stringify(profile));

const getProfileFromStorage = () => {
  const profile = localStorage.getItem(STORAGE.SCH_TV_PROFILE);
  if (profile) {
    return JSON.parse(profile || '');
  }
};

const removeProfileDataFromStorage = () => localStorage.removeItem(STORAGE.SCH_TV_PROFILE);

export { setStorageProfileItem, getProfileFromStorage, removeProfileDataFromStorage };
