export default {
  primary: '#faae1c',
  secondary: '#000000',
  white: '#ffffff',
  black: '#000000',
  gray: '#8d8d8d',
  navBarLink: '#a2a2a2',
  lightGray: '#ebebeb;',
  darkGray: '#707070',
  btnLinkLight: '#c9c9c9',
  nextSlideBG: 'linear-gradient(to left, #000, rgba(84, 84, 84, 0))',
  prevSlideBG: 'linear-gradient(to right, #000, rgba(84, 84, 84, 0))',
  cardContentBG: 'linear-gradient( to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100% )',
  settingsBorderColor: '#707070',
  listSecondary: '#7f7f7f',
  cardOverlay: 'linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5))',
  backgroundOverlay: 'linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.5) 100%)',
  darkInput: '#323232',
  error: '#ff0000',
  divider: '#1ea5de',
  landingHeader: 'linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 100%)',
  landingCircleGradient: 'radial-gradient(circle at 50% 50%, #606977, #000);',
  landingSetcionTitle: '#333334',
  keyFeaturesBullet: '#6b6b6b',
  footerDarkText: '#707070',
  universalSearchGradient: 'linear-gradient(to bottom, #000 0%, rgba(0, 0, 0, 0) 100%)',
  universalSearchField: '#333',
  universalSearchPoper: '#141414',
  landingDarkText: '#a5a5a5',
  tvFrameBorder: '#414141',
};
