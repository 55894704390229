import { channelsApiUrl, getSelectedChannels } from 'api/channelsApi';
import { Navigate } from 'react-router-dom';
import { appLinks } from 'routes/routes';
import useSWR from 'swr';

interface Props {
  children: React.ReactNode;
}

const IsServicesSelected: React.FC<Props> = ({ children }: Props): JSX.Element => {
  const { data: channels } = useSWR(channelsApiUrl.getChannels, getSelectedChannels);

  if (!channels || channels.length === 0) {
    return <Navigate replace to={appLinks.noServices.link} />;
  }

  return <>{children}</>;
};

export default IsServicesSelected;
