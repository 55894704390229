import {
  Bundles,
  BundlesPromise,
  ChannelsAssets,
  ChannelsAssetsPromise,
  CheckHowWorksPromise,
  CheckItWorksMedia,
  HeaderAssets,
  HeaderAssetsPromise,
  HighlightsAssets,
  HighlightsAssetsPromise,
  WhereWatchMediaPromise,
} from 'types/LandingPage';
import { SearchItems, SearchItemsPromise } from 'types/Media';
import { apiClient } from './apiClient';
import ENDPOINTS from './endpoints';

const endpoint = ENDPOINTS.LANDINGPAGE;

const landingPageApiUrl = {
  headerassets: `${endpoint}/headerassets`,
  highlightsassets: `${endpoint}/highlightsassets`,
  channelassets: `${endpoint}/channelassets`,
  universalSearch: `${endpoint}/search`,
  landingPageMedia: (media_id: string | undefined): string => `${endpoint}/media/${media_id}`,
  bundles: `${endpoint}/bundles`,
  checkWorksMedia: `${endpoint}/checkhowitworks`,
  affiliatedeals: `${endpoint}/affiliatedeals`,
};

const getHeaderAssets = (): HeaderAssetsPromise =>
  apiClient.get<HeaderAssets>(landingPageApiUrl.headerassets).then((res) => res.data);

const getHighlightsAssets = (): HighlightsAssetsPromise =>
  apiClient.get<HighlightsAssets>(landingPageApiUrl.highlightsassets).then((res) => res.data);

const getChannelAssets = (): ChannelsAssetsPromise =>
  apiClient.get<ChannelsAssets>(landingPageApiUrl.channelassets).then((res) => res.data);

const getUniversalSearchData = (query: string): SearchItemsPromise =>
  apiClient
    .get<SearchItems>(landingPageApiUrl.universalSearch, { params: { query } })
    .then((res) => res.data);

const getLandingMedia = (media_id: string | undefined): WhereWatchMediaPromise =>
  apiClient.get(landingPageApiUrl.landingPageMedia(media_id)).then((res) => res.data);

const getLandingBundles = (): BundlesPromise =>
  apiClient.get<Bundles>(landingPageApiUrl.bundles).then((res) => res.data);

const getLandingCheckWorksMedia = (): CheckHowWorksPromise =>
  apiClient.get<CheckItWorksMedia>(landingPageApiUrl.checkWorksMedia).then((res) => res.data);

const getAffiliateDeals = (): BundlesPromise =>
  apiClient.get<Bundles>(landingPageApiUrl.affiliatedeals).then((res) => res.data);

export {
  landingPageApiUrl,
  getHeaderAssets,
  getHighlightsAssets,
  getChannelAssets,
  getUniversalSearchData,
  getLandingMedia,
  getLandingBundles,
  getLandingCheckWorksMedia,
  getAffiliateDeals,
};
