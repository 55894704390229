import { Box, Link } from '@mui/material';
import useGoogleAnalytics from 'hooks/useGoogleAnalytics';
import { NavLink } from 'react-router-dom';
import { PROFILE_LINK } from 'routes/routes';
import colors from 'theme/colors';
import { MenuItems } from 'types/MenuItems';

interface Props {
  items: MenuItems | undefined;
}

const NavbarLinks: React.FC<Props> = ({ items }: Props): JSX.Element => {
  const { trackClickOnMenuItem } = useGoogleAnalytics();
  return (
    <Box
      sx={{
        display: { xs: 'none', lg: 'flex' },
        '& .MuiLink-root': {
          color: colors.navBarLink,
          fontSize: 22,
          textDecoration: 'none',
          '&:not(:last-child)': {
            mr: 2,
          },
          '&.active, &:hover': {
            color: colors.white,
          },
        },
      }}
    >
      {items?.map(
        (item, index) =>
          item.isVisible && (
            <Link
              key={index}
              component={NavLink}
              to={`${PROFILE_LINK}/${item?.uid}`}
              onClick={() => trackClickOnMenuItem({ key: item.uid.toUpperCase() })}
            >
              {item.item}
            </Link>
          ),
      )}
    </Box>
  );
};
export default NavbarLinks;
