import { createContext, useContext, useReducer } from 'react';
import {
  iniEpgProgram,
  initCurrentProfile,
  initSelectedHighlight,
  initSelectedProfile,
  initUserData,
} from './init';
import reducer from './reducer';
import { AppStateContext, IAppContext } from './types';

const initialState: AppStateContext = {
  userData: initUserData,
  isAuth: false,
  childProtection: false,
  watchList: [],
  profiles: [],
  selectedProfile: initSelectedProfile,
  isProfileSelected: false,
  currentProfile: initCurrentProfile,
  redirectURL: '',
  backgrounds: [],
  registrationStep: 0,
  selectedServices: [],
  selectedProgram: iniEpgProgram,
  isOverflowOpen: false,
  otherGenreType: 'films',
  otherGenreID: 1,
  isMuted: true,
  isBackgroundMuted: true,
  isVideoEnd: false,
  isShowHighlightPopup: false,
  selectedHighlightAsset: initSelectedHighlight,
  location: '',
  loginType: 'email-password',
  otpSessionID: undefined,
  isWithSHTVdefault: false,
  returnToPortalURL: undefined,
  isVoucherRedeemedSuccesful: false,
};

export const AppContext = createContext<IAppContext>({ state: initialState, dispatch: () => null });

export const useAppContext = (): IAppContext => useContext(AppContext);

interface Props {
  children: React.ReactNode;
}

export const AppStoreProvider = ({ children }: Props): JSX.Element => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const value = { state, dispatch };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};
