import React from 'react';
import { useScrollTrigger, alpha } from '@mui/material';
import colors from 'theme/colors';

interface Props {
  window?: () => Window;
  children: React.ReactElement;
}

const SetBackgroundOnScroll: React.FC<Props> = ({ window, children }: Props) => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    style: {
      backgroundColor: trigger ? `${alpha(colors.black, 0.8)}` : 'transparent',
      paddingTop: trigger ? '0px' : 3,
      paddingBottom: trigger ? '0px' : 3,
      backdropFilter: trigger ? 'blur(7px) contrast(100%)' : 'none',
    },
  });
};

export default SetBackgroundOnScroll;
