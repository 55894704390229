import ReactGA from 'react-ga4';
import { Location } from 'react-router-dom';
import { useAppContext } from 'store';

export enum EventNames {
  ADD_CANCEL_STREAMERS = 'Add/Cancel streamers or services',
  CLICK_MENU_ITEM = 'Click on menu item',
  CLICK_SETTINGS = 'Click on settings',
  USE_DISCOVER = 'Use of Discover',
  USE_TV_GUIDE = 'User of TV Guide',
  STREAMERS_WATCHED = 'Streamers watched',
  CONTENT_ADDED_TO_WATCHLIST = 'Contents added in Watch List',
  CLICK_CONTENT_TV_GUIDE = 'Click on contents from TV Guide',
  CLICK_CONTENT_DISCOVER = 'Click on contents from Discover',
  PLAY_MAIN_TRAILER = 'Play on Main Trailer',
  FIRST_CLICK_HOME = 'First click on Home',
  PROFILE_WATCHING = 'Which profile is watching section',
  CLICK_TRENDING_NOW = 'Click on Trending Now section',
  CLICK_HIGHLIGHTS = 'Click on Highlights section',
  CLICK_CONTINUE_WATCHING = 'Click on Continue watching section',
  CLICK_JUST_FOR_YOU = 'Click on Just for you section',
  CLICK_WATCH_LIST = 'Click on Watch list section',
}

// Define types for event parameters
export interface EventParams {
  application_type?: string;
  profile_id?: number;
  profile_name?: string;
  selected_services?: string;
  media_name?: string;
  channel?: string;
  is_series?: boolean;
  section_name?: string;
  page_name?: string;
  key?: string;
  message?: string;
  page?: string;
  is_streaming?: boolean;
}

// Hook for tracking events in Google Analytics
const useGoogleAnalytics = () => {
  const {
    state: { currentProfile },
  } = useAppContext();

  const googleAnalyticsInit = (trackingId: string) => {
    if (!trackingId) {
      console.warn('Google Analytics Tracking ID is not provided');
      return;
    }

    ReactGA.initialize(trackingId);
  };

  const trackPageView = (location: Location) => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
  };

  const trackCustomEvent = (eventName: EventNames, eventParams: EventParams) => {
    ReactGA.event(eventName, {
      ...eventParams,
      profile_id: currentProfile.activeProfile,
      profile_name: currentProfile.profileName,
    } as EventParams);
  };

  const createEventTracker = (
    eventName: EventNames,
    params: Partial<Omit<EventParams, 'application_type'>> = {},
  ) => {
    return (additionalParams: Partial<EventParams> = {}) => {
      trackCustomEvent(eventName, { application_type: 'WebApp', ...params, ...additionalParams });
    };
  };

  // Custom event functions using the factory function with enum
  const trackAddCancelStreamers = createEventTracker(EventNames.ADD_CANCEL_STREAMERS);
  const trackClickOnMenuItem = createEventTracker(EventNames.CLICK_MENU_ITEM);
  const trackClickOnSettings = createEventTracker(EventNames.CLICK_SETTINGS);
  const trackUseOfDiscover = createEventTracker(EventNames.USE_DISCOVER);
  const trackUseOfTVGuide = createEventTracker(EventNames.USE_TV_GUIDE);
  const trackStreamersWatched = createEventTracker(EventNames.STREAMERS_WATCHED);
  const trackContentAddedToWatchList = createEventTracker(EventNames.CONTENT_ADDED_TO_WATCHLIST);
  const trackClickOnContentFromTVGuide = createEventTracker(EventNames.CLICK_CONTENT_TV_GUIDE);
  const trackClickOnContentFromDiscover = createEventTracker(EventNames.CLICK_CONTENT_DISCOVER);
  const trackPlayOnMainTrailer = createEventTracker(EventNames.PLAY_MAIN_TRAILER);
  const trackFirstClickOnHome = createEventTracker(EventNames.FIRST_CLICK_HOME);
  const trackProfileWatching = createEventTracker(EventNames.PROFILE_WATCHING);
  const trackClickOnTrendingNow = createEventTracker(EventNames.CLICK_TRENDING_NOW);
  const trackClickOnHighlights = createEventTracker(EventNames.CLICK_HIGHLIGHTS);
  const trackClickOnContinueWatching = createEventTracker(EventNames.CLICK_CONTINUE_WATCHING);
  const trackClickOnJustForYou = createEventTracker(EventNames.CLICK_JUST_FOR_YOU);
  const trackClickOnWatchList = createEventTracker(EventNames.CLICK_WATCH_LIST);

  return {
    googleAnalyticsInit,
    trackPageView,
    trackAddCancelStreamers,
    trackClickOnMenuItem,
    trackClickOnSettings,
    trackUseOfDiscover,
    trackUseOfTVGuide,
    trackStreamersWatched,
    trackContentAddedToWatchList,
    trackClickOnContentFromTVGuide,
    trackClickOnContentFromDiscover,
    trackPlayOnMainTrailer,
    trackFirstClickOnHome,
    trackProfileWatching,
    trackClickOnTrendingNow,
    trackClickOnHighlights,
    trackClickOnContinueWatching,
    trackClickOnJustForYou,
    trackClickOnWatchList,
  };
};

export default useGoogleAnalytics;
