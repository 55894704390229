import { Box, Typography } from '@mui/material';
import { NavLink, useMatch, useResolvedPath } from 'react-router-dom';
import { PROFILE_LINK } from 'routes/routes';
import colors from 'theme/colors';
import { MenuItem } from 'types/MenuItems';

interface Props {
  link: MenuItem;
}

const NavigationLink: React.FC<Props> = (props: Props): JSX.Element => {
  const { uid, item, unselectedIcon, selectedIcon } = props.link;

  const resolved = useResolvedPath(`${PROFILE_LINK}/${uid}`);
  const isMatch = useMatch({
    path: resolved.pathname,
    end: true,
  });

  return (
    <Typography
      component={NavLink}
      to={`${PROFILE_LINK}/${uid}`}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        position: 'relative',
        padding: '1.25rem 0',
        fontSize: '1.4375rem',
        color: colors.gray,
        textDecoration: 'none',

        '&:hover': {
          color: colors.white,
          cursor: 'pointer',
          '& .nav-icon': {
            borderBottom: `3px solid ${colors.primary}`,
          },
        },
        '&.active': {
          color: colors.white,
          '& .nav-icon': {
            borderBottom: `3px solid ${colors.primary}`,
          },
          '& .MuiSvgIcon-root': {
            color: colors.white,
          },
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <Box
          component={'span'}
          className={'nav-icon'}
          sx={{
            display: 'inline-block',
            mr: 2,
            fontSize: '1.1em',
            width: '38px',
            textAlign: 'center',
            borderBottom: '3px solid transparent',
          }}
        >
          <Box component='img' src={!isMatch ? unselectedIcon : selectedIcon} alt={item} />
        </Box>

        <Box component={'span'}>{item}</Box>
      </Box>
    </Typography>
  );
};

export default NavigationLink;
