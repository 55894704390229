import { deleteAuthorizationHeader, setAuthorizationHeader } from 'api/apiClient';
import { setCurrentProfile, signIn, signInWithSessionID } from 'api/authApi';
import { COOKIES, removeTokenFromCookie, setTokenToCookie } from 'helpers/Cookies';
import { useNavigate } from 'react-router-dom';
import { appLinks } from 'routes/routes';
import { useAppContext } from 'store';
import { setIsAuth, setIsProfileSelected, setProfile, setUserData } from 'store/actions';
import { initUserData } from 'store/init';
import { LoginParams, LoginRequestParams, LoginWithSessionIDParams } from 'types/Auth';
import useAxiosRequest from './useApiRequest';

const useAuth = () => {
  const { dispatch } = useAppContext();
  const { requestFunc, isLoading, error } = useAxiosRequest();

  const navigate = useNavigate();

  const setUserAuth = (isAuth: boolean): void => {
    dispatch(setIsAuth(isAuth));
  };

  const login = async (values: LoginParams, requestParams?: LoginRequestParams) => {
    const res = await requestFunc(signIn(values, requestParams));
    return res;
  };

  const loginWithSessionID = async (values: LoginWithSessionIDParams) => {
    const res = await requestFunc(signInWithSessionID(values));
    return res;
  };

  const logout = () => {
    navigate(appLinks.home.link, { replace: true });
    deleteAuthorizationHeader();
    dispatch(setIsAuth(false));
    dispatch(setUserData(initUserData));
    removeTokenFromCookie(COOKIES.SCH_TV_ACCESS_COOKIE);
  };

  const setUserProfile = async (profile_id: number | string) => {
    const profile = await requestFunc(setCurrentProfile(profile_id));
    if (profile?.token) {
      setAuthorizationHeader(profile.token);
      setUserAuth(true);
      setTokenToCookie(COOKIES.SCH_TV_ACCESS_COOKIE, profile.token);
      dispatch(setProfile(profile));
      dispatch(setIsProfileSelected(true));
    }
  };

  const handleSetProfile = async (profile_id: number | string) => {
    await setUserProfile(profile_id);
    navigate(appLinks.tvGuide.link, { replace: true });
  };

  return {
    setUserAuth,
    login,
    loginWithSessionID,
    logout,
    setUserProfile,
    isLoading,
    error,
    handleSetProfile,
  };
};

export default useAuth;
