import { COOKIES, getTokenFromCookie, removeTokenFromCookie } from 'helpers/Cookies';
import { useAppContext } from 'store';
import { setIsAuth } from 'store/actions';
import jwt_decode from 'jwt-decode';
import { setAuthorizationHeader } from 'api/apiClient';
import { useNavigate } from 'react-router-dom';
import { appLinks } from 'routes/routes';
import { useLayoutEffect, useState } from 'react';

export interface IToken {
  profile: string;
  exp: number;
}

export type Token = IToken;

const useJWT = () => {
  const { dispatch } = useAppContext();

  const navigate = useNavigate();

  const accessToken = getTokenFromCookie(COOKIES.SCH_TV_ACCESS_COOKIE);

  const [decodeToken, setDecodeToken] = useState<Token | undefined>(undefined);
  const [isExpired, setIsExpired] = useState(false);

  useLayoutEffect(() => {
    if (accessToken) {
      const token = jwt_decode<IToken>(accessToken);
      setDecodeToken(token);
    }
  }, [accessToken]);

  useLayoutEffect(() => {
    if (accessToken && decodeToken) {
      Date.now() >= decodeToken?.exp * 1000 && setIsExpired(true);
    } else {
      setIsExpired(false);
    }
  }, [accessToken, decodeToken]);

  const redirectIfAuth = () => {
    if (accessToken && !isExpired) {
      setAuthorizationHeader(accessToken);
      dispatch(setIsAuth(true));
    } else {
      dispatch(setIsAuth(false));
      navigate(appLinks.login.link, { replace: true });
      removeTokenFromCookie(COOKIES.SCH_TV_ACCESS_COOKIE);
    }
  };

  return { accessToken, decodeToken, isExpired, redirectIfAuth };
};

export default useJWT;
