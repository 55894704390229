import { AppStateContext, AppActions, AppActionsEnums } from './types';

const reducer = (state: AppStateContext, action: AppActions): AppStateContext => {
  switch (action.type) {
    case AppActionsEnums.SET_USER_DATA:
      return {
        ...state,
        userData: action.payload,
      };
    case AppActionsEnums.SET_IS_AUTH:
      return {
        ...state,
        isAuth: action.payload,
      };
    case AppActionsEnums.SET_CHILD_PROTECTION:
      return {
        ...state,
        childProtection: action.payload,
      };
    case AppActionsEnums.SET_WATCH_LIST:
      return {
        ...state,
        watchList: action.payload,
      };
    case AppActionsEnums.SET_PROFILES:
      return {
        ...state,
        profiles: action.payload,
      };
    case AppActionsEnums.SET_SELECTED_PROFILE:
      return {
        ...state,
        selectedProfile: action.payload,
      };
    case AppActionsEnums.SET_IS_PROFILE_SELECTED:
      return {
        ...state,
        isProfileSelected: action.payload,
      };
    case AppActionsEnums.SET_PROFILE:
      return {
        ...state,
        currentProfile: action.payload,
      };
    case AppActionsEnums.SET_REDIRECT_URL:
      return {
        ...state,
        redirectURL: action.payload,
      };
    case AppActionsEnums.SET_BACKGROUNDS:
      return {
        ...state,
        backgrounds: action.payload,
      };
    case AppActionsEnums.SET_REGISTRATION_STEP:
      return {
        ...state,
        registrationStep: action.payload,
      };

    case AppActionsEnums.SET_SERVICE_TO_SELECTED:
      return {
        ...state,
        selectedServices: [...state.selectedServices, action.payload],
      };
    case AppActionsEnums.SET_SELECTED_SERVICES:
      return {
        ...state,
        selectedServices: action.payload,
      };

    case AppActionsEnums.CLEAR_SELECTED_SERVICES:
      return {
        ...state,
        selectedServices: [],
      };
    case AppActionsEnums.REMOVE_SERVICE_FROM_SELECTED:
      return {
        ...state,
        selectedServices: state.selectedServices.filter((item) => item.id !== action.payload),
      };
    case AppActionsEnums.SET_SELECTED_EPG_PROGRAM:
      return {
        ...state,
        selectedProgram: action.payload,
      };
    case AppActionsEnums.SET_OVERFLOW_IS_OPEN:
      return {
        ...state,
        isOverflowOpen: action.payload,
      };
    case AppActionsEnums.SET_OTHER_GENRE_TYPE:
      return {
        ...state,
        otherGenreType: action.payload,
      };
    case AppActionsEnums.SET_OTHER_GENRE_ID:
      return {
        ...state,
        otherGenreID: action.payload,
      };
    case AppActionsEnums.SET_IS_MUTED:
      return {
        ...state,
        isMuted: action.payload,
      };

    case AppActionsEnums.SET_IS_BACKGROUND_MUTED:
      return {
        ...state,
        isBackgroundMuted: action.payload,
      };
    case AppActionsEnums.SET_IS_VIDEO_END:
      return {
        ...state,
        isVideoEnd: !state.isVideoEnd,
      };
    case AppActionsEnums.SET_IS_SHOW_HIGHLIGHT_POPUP:
      return {
        ...state,
        isShowHighlightPopup: action.payload,
      };
    case AppActionsEnums.SET_SELECTED_HIGHLIGHT_ASSET:
      return {
        ...state,
        selectedHighlightAsset: action.payload,
      };
    case AppActionsEnums.SET_LOCATION:
      return {
        ...state,
        location: action.payload,
      };
    case AppActionsEnums.SET_LOGIN_TYPE:
      return {
        ...state,
        loginType: action.payload,
      };
    case AppActionsEnums.SET_OTP_SESSION_ID:
      return {
        ...state,
        otpSessionID: action.payload,
      };
    case AppActionsEnums.SET_IS_WITH_SHTV_DEFAULT:
      return {
        ...state,
        isWithSHTVdefault: action.payload,
      };
    case AppActionsEnums.SET_RETURN_TO_PORTAL_URL:
      return {
        ...state,
        returnToPortalURL: action.payload,
      };
    case AppActionsEnums.SET_IS_VOUCHER_REDEEMED_SUCCESFUL:
      return {
        ...state,
        isVoucherRedeemedSuccesful: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
