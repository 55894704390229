import { setApiLanguageHeader, setAuthorizationHeader } from 'api/apiClient';
import useJWT from 'hooks/useJWT';

import { useLayoutEffect } from 'react';
import { useAppContext } from 'store';
import { setIsAuth } from 'store/actions';

interface Props {
  children: React.ReactNode;
}

const UserAuthData: React.FC<Props> = ({ children }: Props): JSX.Element => {
  const { accessToken } = useJWT();

  const { dispatch } = useAppContext();

  useLayoutEffect(() => {
    const langCode = localStorage.getItem('i18nextLng') || 'en';
    setApiLanguageHeader(langCode);

    if (accessToken) {
      dispatch(setIsAuth(true));
      setAuthorizationHeader(accessToken);
    }
    // eslint-disable-next-line
  }, []);

  return <>{children}</>;
};

export default UserAuthData;
