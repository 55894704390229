import { Token } from 'hooks/useJWT';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import 'moment-duration-format';

const getCurrentYear = () => new Date().getFullYear();

const formatToYear = (date: string) => moment(date).format('YYYY');
const formatToYearMonthDay = (date: Date) => moment(date).format('YYYY-MM-DD');

const minutesToHours = (runtime: number): string => {
  const t = moment.duration(runtime, 'minutes').format('h[h] m[min]');
  return `${t}`;
};

const millisecondsToHours = (duration: string | number) => {
  const t = moment.duration(duration, 'seconds').format('h[h] m[min]');
  const time = Number(t) > 0 && t;
  return time;
};

const numberToAmPm = (h: number | string): string => {
  return moment(`${h}`, 'hh:mm').format('hh:mm a');
};

const toUnixTime = (hour: string | number): string => {
  const h = moment(hour, 'HH:mm:ss').unix();
  return h.toString();
};

const getCurrentDate = () => {
  return moment().format('YYYY-MM-DD');
};

const diffByHours = (time: string) => {
  return Math.abs(moment('00:00:00', 'H:mm:ss').diff(moment(time, 'H:mm:ss'), 'm'));
};

const hourWithOutMinutes = (time: string) => {
  return moment(time, 'H:mm:ss').format('hh:mm a');
};

const getCurrentHour = () => {
  return moment().hours();
};

const getCurrentTime = () => {
  return moment().format('H:mm:ss');
};

const isBeforeTime = (time: string) => {
  return moment().isBefore(moment(time, 'h:mm:ss'));
};

const isAfterTime = (time: string) => {
  return moment().isAfter(moment(time, 'h:mm:ss'));
};

const isBetweenTime = (start: string, end: string) => {
  const format = 'h:mm:ss';
  return moment().isBetween(moment(start, format), moment(end, format));
};

const setCookieExpires = (accessToken: string) => {
  const token = jwtDecode<Token>(accessToken);

  const tokenExpires = moment.unix(token.exp).format('YYYY-MM-DD');
  const cookieExpires = moment(tokenExpires).diff(getCurrentDate(), 'days');

  return cookieExpires;
};

const setVideoDuration = (currentTime: number, duration: number): string => {
  const videoCurrentTime = moment
    .duration(currentTime * 1000 <= 1000 ? 1 : currentTime, 'seconds')
    .format();
  const videoDuration = moment.duration(duration, 'seconds').format();
  return `${videoCurrentTime}/${videoDuration}`;
};

export {
  getCurrentYear,
  formatToYearMonthDay,
  formatToYear,
  millisecondsToHours,
  minutesToHours,
  toUnixTime,
  numberToAmPm,
  getCurrentDate,
  diffByHours,
  hourWithOutMinutes,
  getCurrentHour,
  getCurrentTime,
  isBeforeTime,
  isAfterTime,
  isBetweenTime,
  setCookieExpires,
  setVideoDuration,
};
