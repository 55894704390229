import { SvgIcon, SvgIconProps } from '@mui/material';
import { FC } from 'react';

const SuccessIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      width='22'
      height='22.001'
      viewBox='0 0 22 22.001'
      {...props}
    >
      <g data-name='Group 10220'>
        <path
          data-name='Subtraction 1'
          d='M11 22A11 11 0 0 1 3.222 3.222a11 11 0 0 1 15.557 15.557A10.929 10.929 0 0 1 11 22zM5.323 10.049a1.043 1.043 0 0 0-1.018.8.146.146 0 0 0 0 .018c0 .019-.005.038-.028.048v.363a1.536 1.536 0 0 0 .357.615c.935.931 1.879 1.875 2.792 2.789l.837.838a1.083 1.083 0 0 0 .451.292 1.181 1.181 0 0 0 .35.056 1.094 1.094 0 0 0 .783-.357l3.664-3.665 3.664-3.665a2.084 2.084 0 0 0 .162-.185 1.053 1.053 0 0 0-.5-1.613 1.2 1.2 0 0 0-.352-.056 1.1 1.1 0 0 0-.793.363l-2.458 2.451a3951.558 3951.558 0 0 0-4.081 4.085c-.042.042-.073.06-.1.06s-.064-.024-.1-.064q-.573-.561-1.141-1.122a908.216 908.216 0 0 1-1.7-1.7 1.114 1.114 0 0 0-.789-.351z'
          style={{
            fill: '#10b980',
          }}
        />
      </g>
    </SvgIcon>
  );
};

export default SuccessIcon;
