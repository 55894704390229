import { SvgIcon, SvgIconProps } from '@mui/material';

const SearchIcon: React.FC<SvgIconProps> = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <g style={{ fill: 'none' }}>
        <path
          d='M8.844 0A8.844 8.844 0 1 1 0 8.844 8.844 8.844 0 0 1 8.844 0z'
          style={{ stroke: 'none' }}
        />
        <path
          d='M8.844 2A6.852 6.852 0 0 0 2 8.844a6.852 6.852 0 0 0 6.844 6.845 6.852 6.852 0 0 0 6.845-6.845A6.852 6.852 0 0 0 8.844 2m0-2a8.844 8.844 0 1 1 0 17.689A8.844 8.844 0 0 1 8.844 0z'
          style={{ fill: '#fff', stroke: 'none' }}
        />
      </g>
      <rect
        width='10.935'
        height='2.853'
        rx='1.426'
        transform='rotate(45.97 -7.715 23.537)'
        style={{ fill: '#fff' }}
      />
    </SvgIcon>
  );
};

export default SearchIcon;
