import { lazy, useEffect } from 'react';
import { useRoutes, useLocation, Routes, Route } from 'react-router-dom';
import { appLinks } from './routes';

import RequireAuth from './RequireAuth';
import RequireUserData from './RequireUserData';
import ProfileLayout from 'app/ProfileLayout';

const Landing = lazy(() => import('containers/Landing/index'));
import LandingMedia from 'containers/Pages/LandingMedia';

const Registration = lazy(() => import('containers/Auth/Registration'));
const RegistrationIndex = lazy(() => import('containers/Auth/Registration/RegistrationIndex'));
const RegistrationPayment = lazy(() => import('containers/Auth/Registration/StepPayment'));
const ExternalReceipt = lazy(() => import('containers/Auth/Registration/ExternalReceipt'));

const BundleReceipt = lazy(
  () => import('containers/Auth/Registration/StepsRegistration/BundleReceipt'),
);

const Login = lazy(() => import('containers/Auth/Login'));
const Profiles = lazy(() => import('containers/Auth/Profiles/index'));
const ProfilesList = lazy(() => import('containers/Auth/Profiles/List'));
const ProfilesManage = lazy(() => import('containers/Auth/Profiles/Manage/index'));
const UpdateProfile = lazy(() => import('containers/Auth/Profiles/Update/index'));
const NewProfile = lazy(() => import('containers/Auth/Profiles/CreateOrUpdate/index'));
const ForgotPassword = lazy(() => import('containers/Auth/ForgotPassword'));
const ChangePassword = lazy(() => import('containers/Auth/ChangePassword'));
const NewPassword = lazy(() => import('containers/Auth/NewPassword'));
const Invitation = lazy(() => import('containers/Auth/Invitation'));

const Layout = lazy(() => import('app/Layout'));

const Discover = lazy(() => import('containers/Pages/Profile/Pages/Discover'));
const WatchList = lazy(() => import('containers/Pages/Profile/Pages/WatchList'));
const TVGuide = lazy(() => import('containers/Pages/Profile/Pages/TVGuide'));
const LiveTV = lazy(() => import('containers/Pages/Profile/Pages/LiveTV'));
const ShowPage = lazy(() => import('containers/Pages/Profile/Pages/ShowPage'));
const ShowPageMedia = lazy(() => import('containers/Pages/Profile/Pages/ShowPage/Media'));
const Details = lazy(() => import('containers/Pages/Details'));
const AccountDetails = lazy(() => import('containers/Pages/Details/Account'));
const PasswordDetails = lazy(() => import('containers/Pages/Details/Password'));
const OtherGenres = lazy(() => import('containers/Pages/OtherGenres'));
const Offers = lazy(() => import('containers/Pages/Profile/Pages/Offers'));
const Settings = lazy(() => import('containers/Pages/Settings'));
const Search = lazy(() => import('containers/Pages/Profile/Pages/Search'));
const Services = lazy(() => import('containers/Pages/Profile/Pages/Services'));
const NoSelectedServices = lazy(() => import('containers/Placeholders/NoSelectedServices'));
const Error = lazy(() => import('containers/Pages/Error'));
const Success = lazy(() => import('containers/Pages/Success'));
const AppSuccess = lazy(() => import('containers/Pages/AppSuccess'));

const QRPPayment = lazy(() => import('containers/Auth/Registration/QRPPayment'));
const QrpThankYou = lazy(() => import('containers/Pages/QrpThankYou'));
const TRIC = lazy(() => import('containers/TRIC'));
const Support = lazy(() => import('containers/Pages/Support'));
const AvailableStreamingServices = lazy(
  () => import('containers/Pages/AvailableStreamingServices'),
);

const Invite = lazy(() => import('containers/Invite'));

const NoMatches = lazy(() => import('containers/Pages/NoMatches'));

const Placeholder = lazy(() => import('containers/Pages/Placeholder'));

// import RedirectIfAuth from 'containers/Auth/RedirectIfAuth';
import IsServicesSelected from 'routes/middleware/IsServicesSelected';

import SetCurrentProfile from 'components/SetCurrentProfile';
import Deactivate from 'containers/Deactivate';
import useGoogleAnalytics from 'hooks/useGoogleAnalytics';

const AppRoutes: React.FC = () => {
  const location = useLocation();
  const state = location.state as { backgroundLocation?: Location };

  const { trackPageView } = useGoogleAnalytics();

  useEffect(() => {
    trackPageView(location);
  }, [trackPageView, location]);

  const appRoutes = useRoutes(
    [
      {
        path: appLinks.home.link,
        element: <Layout />,
        children: [
          {
            path: appLinks.placeholder.link,
            element: <Placeholder />,
          },
          {
            path: appLinks.login.link,
            element: (
              // <CheckVoucher>
              <Login />
              // </CheckVoucher>
            ),
          },
          {
            path: appLinks.deactivate.link,
            element: <Deactivate />,
          },
          {
            path: appLinks.support.link,
            element: <Support />,
          },
          {
            path: appLinks.availableStreamingServices.link,
            element: <AvailableStreamingServices />,
          },
          {
            path: appLinks.forgotPassword.link,
            element: <ForgotPassword />,
          },
          {
            path: appLinks.changePassword.link,
            element: <ChangePassword />,
          },
          {
            path: appLinks.newPassword.link,
            element: <NewPassword />,
          },
          {
            path: appLinks.profiles.link,
            element: (
              <RequireUserData>
                <Profiles />
              </RequireUserData>
            ),
            children: [
              {
                path: appLinks.newProfile.link,
                element: <NewProfile />,
              },
              {
                path: appLinks.manageProfiles.link,
                element: <ProfilesManage />,
              },
              {
                path: appLinks.updateProfile.link,
                element: <UpdateProfile />,
              },
              {
                index: true,
                element: <ProfilesList />,
              },
            ],
          },
          {
            path: appLinks.profile.link,
            element: (
              <RequireAuth>
                <SetCurrentProfile>
                  <ProfileLayout />
                </SetCurrentProfile>
              </RequireAuth>
            ),
            children: [
              {
                path: appLinks.tvGuide.link,
                element: (
                  <IsServicesSelected>
                    <TVGuide />
                  </IsServicesSelected>
                ),
              },
              {
                path: appLinks.discover.link,
                element: (
                  <IsServicesSelected>
                    <Discover />
                  </IsServicesSelected>
                ),
              },
              {
                path: appLinks.watchlist.link,
                element: (
                  <IsServicesSelected>
                    <WatchList />
                  </IsServicesSelected>
                ),
              },
              {
                path: appLinks.liveTV.link,
                element: (
                  <IsServicesSelected>
                    <LiveTV />
                  </IsServicesSelected>
                ),
              },
              {
                path: appLinks.genres.link,
                element: (
                  <IsServicesSelected>
                    <OtherGenres />
                  </IsServicesSelected>
                ),
              },
              {
                path: appLinks.search.link,
                element: (
                  <IsServicesSelected>
                    <Search />
                  </IsServicesSelected>
                ),
              },
              {
                path: appLinks.offers.link,
                element: <Offers />,
              },
              {
                path: appLinks.services.link,
                element: <Services />,
              },
              {
                path: appLinks.noServices.link,
                element: <NoSelectedServices />,
              },
              {
                path: appLinks.settings.link,
                element: <Settings />,
              },

              {
                path: appLinks.accountDetails.link,
                element: <Details />,
                children: [
                  {
                    path: appLinks.passwordDetail.link,
                    element: <PasswordDetails />,
                  },
                  {
                    index: true,
                    element: <AccountDetails />,
                  },
                ],
              },
              {
                path: appLinks.media.link,
                element: <ShowPage />,
                children: [
                  {
                    path: appLinks.singleMedia.link,
                    element: <ShowPageMedia />,
                  },
                ],
              },
            ],
          },
          {
            path: appLinks.qrpPayment.link,
            element: (
              <RequireAuth>
                <QRPPayment />
              </RequireAuth>
            ),
          },
          {
            path: appLinks.qrpThankYou.link,
            element: <QrpThankYou />,
          },
          {
            path: appLinks.invite.link,
            element: (
              <RequireAuth>
                <Invite />
              </RequireAuth>
            ),
          },
          {
            path: appLinks.watchMovies.link,
            element: (
              <RequireAuth>
                <Invite />
              </RequireAuth>
            ),
          },
          {
            path: appLinks.watchShows.link,
            element: (
              <RequireAuth>
                <Invite />
              </RequireAuth>
            ),
          },
          {
            path: appLinks.invitation.link,
            element: <Invitation />,
          },
          {
            path: appLinks.register.link,
            element: <Registration />,
            children: [
              {
                path: appLinks.registerPayment.link,
                element: <RegistrationPayment />,
              },
              {
                path: appLinks.registerReceipt.link,
                element: <BundleReceipt />,
              },
              {
                path: appLinks.externalReceipt.link,
                element: <ExternalReceipt />,
              },
              {
                index: true,
                element: <RegistrationIndex />,
              },
            ],
          },
          {
            path: appLinks.registerPayment.link,
            element: <RegistrationPayment />,
          },
          {
            index: true,
            element: <Landing />,
          },
          {
            path: appLinks.thankYou.link,
            element: <Success />,
          },
          {
            path: appLinks.appThankYou.link,
            element: <AppSuccess />,
          },
          {
            path: appLinks.error.link,
            element: <Error />,
          },
          {
            path: appLinks.tric.link,
            element: <TRIC />,
          },
          {
            path: '*',
            element: <NoMatches />,
          },
        ],
      },
    ],
    state?.backgroundLocation || location,
  );
  return (
    <>
      {appRoutes}
      {state?.backgroundLocation && (
        <Routes>
          <Route path={appLinks.landingMedia.link} element={<LandingMedia />} />
        </Routes>
      )}
    </>
  );
};

export default AppRoutes;
