import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  OutlinedInput,
  Stack,
  Typography,
} from '@mui/material';
import { deactivateAccount } from 'api/authApi';
import PasswordInput from 'components/Forms/Inputs/PasswordInput';
import FullHeightContainer from 'components/FullHeightContainer';
import AlertIcon from 'components/icons/AlertIcon';
import SuccessIcon from 'components/icons/SuccessIcon';
import AuthLayoutWrapper from 'components/Wrappers/AuthLayoutWrapper';
import Success from 'containers/Pages/Success';
import parse from 'html-react-parser';
import { useFormik } from 'formik';
import useAxiosRequest from 'hooks/useApiRequest';

import usePageBackground from 'hooks/usePageBackground';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BackgroundType, PageType } from 'theme/background';
import colors from 'theme/colors';

interface FormData {
  email: string;
  password: string;
}

const initialValues = {
  email: '',
  password: '',
};

const Deactivate: FC = () => {
  const { backgroundURL } = usePageBackground(BackgroundType.IMAGE, PageType.LOGIN);

  const { requestFunc } = useAxiosRequest();

  const [isDeletingError, setIsDeletingError] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);

  const [message, setMessage] = useState('');

  const { handleSubmit, values, handleChange } = useFormik<FormData>({
    initialValues,

    onSubmit: async (values: FormData) => {
      setIsDeletingError(false);
      setIsDeleted(false);
      const res = await requestFunc(deactivateAccount(values));
      if (!res) {
        setIsDeletingError((prevState) => !prevState);
      } else {
        setMessage(res?.message);
        setIsDeleted((prevState) => !prevState);
      }
    },
  });

  const { t } = useTranslation();
  const { isLoading } = useAxiosRequest();
  return (
    <AuthLayoutWrapper isLogo={true} background={backgroundURL}>
      <FullHeightContainer
        sx={{
          px: { xs: 2, sm: 0 },
        }}
      >
        <Container
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            height: '100vh',
            px: { xs: 2, sm: 0 },
          }}
        >
          <Stack
            spacing={2.4}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography fontSize={{ xs: 20, md: 23 }} fontWeight='bold' textAlign='center'>
              {t('deactivateAccount.subTitle')}
            </Typography>
            <Typography
              color='#ebebeb'
              textAlign='center'
              fontSize={{ xs: 14, md: 20 }}
              sx={{
                width: 1,
                maxWidth: 974,
              }}
            >
              {parse(t('deactivateAccount.subText1'))}
            </Typography>

            <Typography
              color='#ebebeb'
              textAlign='center'
              fontSize={{ xs: 14, md: 20 }}
              sx={{
                width: 1,
                maxWidth: 570,
              }}
            >
              {parse(t('deactivateAccount.subText2'))}
            </Typography>
          </Stack>

          <Grid
            container
            component='form'
            onSubmit={handleSubmit}
            spacing={1}
            flexDirection='row'
            alignItems='center'
            sx={{
              width: 1,
              maxWidth: 360,
              margin: '0 auto',
              '& :-webkit-autofill': {
                transitionDuration: '0s',
                transitionDelay: '99999s !important',
              },
            }}
          >
            <Grid
              item
              xs={12}
              sx={{
                mt: 2,
              }}
            >
              <OutlinedInput
                name='email'
                type='email'
                fullWidth
                placeholder={t('registration.stepOne.form.placeholder.email')}
                value={values.email}
                onChange={handleChange}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                mb: 2,
              }}
            >
              <PasswordInput
                fullWidth
                autoComplete='false'
                name='password'
                placeholder={t('login.placeholder.password')}
                value={values.password}
                onChange={handleChange}
              />
            </Grid>

            <Grid
              item
              xs={12}
              display='flex'
              flexDirection='column'
              justifyContent='center'
              sx={{
                '& .MuiButton-text': {
                  color: colors.primary,
                },
              }}
            >
              <Button
                fullWidth
                type='submit'
                variant='outlined'
                size='large'
                startIcon={isLoading ? <CircularProgress size={20} /> : undefined}
                sx={{
                  fontWeight: 500,
                }}
              >
                {!isLoading ? t('deactivateAccount.subButton') : t('loading')}
              </Button>
            </Grid>

            {isDeleted && (
              <Box
                sx={{
                  width: 1,
                  border: 'solid 1px #10b980',
                  mt: '22px',
                  borderRadius: '5px',
                  p: 4,
                }}
              >
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: 'bold',
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <SuccessIcon
                    sx={{
                      mr: '18px',
                    }}
                  />
                  {message}
                </Typography>
              </Box>
            )}

            {isDeletingError && (
              <Box
                sx={{
                  width: 1,
                  border: 'solid 1px #f04444',
                  mt: '22px',
                  borderRadius: '5px',
                  p: 4,
                }}
              >
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: 'bold',
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <AlertIcon
                    sx={{
                      mr: '18px',
                      mb: '15px',
                    }}
                  />
                  {t('login.errors.wrongCredentials.text')}
                </Typography>
                <Typography sx={{ fontSize: '16px', fontWeight: 'normal' }}>
                 {t('login.errors.wrongCredentials.text2')}
                </Typography>
              </Box>
            )}
          </Grid>
        </Container>
      </FullHeightContainer>
    </AuthLayoutWrapper>
  );
};

export default Deactivate;
