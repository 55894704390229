import { NavLink } from 'react-router-dom';
import { Box, Link } from '@mui/material';
import { appLinks } from 'routes/routes';
import LogoIMG from 'assets/images/LogoScreenHitsWhite.svg';
import useGoogleAnalytics from 'hooks/useGoogleAnalytics';

const Logo: React.FC = (): JSX.Element => {
  const { trackFirstClickOnHome } = useGoogleAnalytics();
  return (
    <Link
      component={NavLink}
      to={appLinks.tvGuide.link}
      sx={{ display: 'flex', width: { xs: 130, sm: 140, lg: 175 }, height: 20 }}
      onClick={() => trackFirstClickOnHome()}
    >
      <Box component='img' src={LogoIMG} alt='Screen hits' sx={{ width: 1 }} />
    </Link>
  );
};

export default Logo;
