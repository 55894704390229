import { WatchList, WatchListPromise } from 'types/Media';
import { apiClient } from './apiClient';
import ENDPOINTS from './endpoints';

const endpoint = ENDPOINTS.MEDIA;

const watchListApiUrl = {
  getList: `${endpoint}/mylist`,
  addRemoveFromList: `${endpoint}/addorremovefromlist`,
};

const getWatchList = (): WatchListPromise =>
  apiClient.get<WatchList>(watchListApiUrl.getList).then((res) => res.data);

const addOrRemoveFromWatchList = (id: string | number): Promise<{ message: string }> =>
  apiClient
    .post<{ message: string }>(watchListApiUrl.addRemoveFromList, { id })
    .then((res) => res.data);

export { watchListApiUrl, getWatchList, addOrRemoveFromWatchList };
