import { Box, IconButton, Typography } from '@mui/material';
import XMarkIcon from 'components/icons/XMarkIcon';
import DialogWrapper from '../Wrapper';
import { useTranslation } from 'react-i18next';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const TermsAndConditions: React.FC<Props> = ({ isOpen, onClose }: Props): JSX.Element => {
  const { t } = useTranslation();
  return (
    <>
     <DialogWrapper isOpen={isOpen} title={t('termsofUse.title')}>
  <IconButton
    size='large'
    sx={(theme) => ({
      position: 'absolute',
      top: theme.spacing(1),
      right: theme.spacing(1),
      color: '#fff',
      zIndex: theme.zIndex.modal + 2,
    })}
    onClick={onClose}
  >
    <XMarkIcon />
  </IconButton>
  <Box>
    <Typography sx={{ fontSize: '22px', fontWeight: 700, textAlign: 'left' }}>
      {t('termsofUse.legalInfoTitle')}
    </Typography>
    <Typography variant='body1' textAlign='justify'>
      {t('termsofUse.legalInfoText1')}
    </Typography>
    <Typography variant='body1' textAlign='justify'>
      {t('termsofUse.legalInfoText2')}
    </Typography>
    <Typography sx={{ fontSize: '22px', fontWeight: 700, textAlign: 'left' }}>
      {t('termsofUse.contentTitle')}
    </Typography>
    <Typography variant='body1' textAlign='justify'>
      {t('termsofUse.contentText1')}
    </Typography>
    <Typography variant='body1' textAlign='justify'>
      {t('termsofUse.contentText2')}
    </Typography>
    <Typography sx={{ fontSize: '22px', fontWeight: 700, textAlign: 'left' }}>
      {t('termsofUse.useOfSiteTitle')}
    </Typography>
    <Typography variant='body1' textAlign='justify'>
      {t('termsofUse.useOfSiteText1')}
    </Typography>
    <Typography variant='body1' textAlign='justify'>
      {t('termsofUse.useOfSiteText2')}
    </Typography>
    <Typography sx={{ fontSize: '22px', fontWeight: 700, textAlign: 'left' }}>
      {t('termsofUse.purchaseTitle')}
    </Typography>
    <Typography variant='body1' textAlign='justify'>
      {t('termsofUse.purchaseText1')}
    </Typography>
    <Typography sx={{ fontSize: '22px', fontWeight: 700, textAlign: 'left' }}>
      {t('termsofUse.accountsTitle')}
    </Typography>
    <Typography variant='body1' textAlign='justify'>
      {t('termsofUse.accountsText1')}
    </Typography>
    <Typography sx={{ fontSize: '22px', fontWeight: 700, textAlign: 'left' }}>
      {t('termsofUse.privacyTitle')}
    </Typography>
    <Typography variant='body1' textAlign='justify'>
      {t('termsofUse.privacyText')}
    </Typography>
    <Typography sx={{ fontSize: '22px', fontWeight: 700, textAlign: 'left' }}>
      {t('termsofUse.linksTitle')}
    </Typography>
    <Typography variant='body1' textAlign='justify'>
      {t('termsofUse.linksText')}
    </Typography>

    <Typography sx={{ fontSize: '22px', fontWeight: 700, textAlign: 'left' }}>
      {t('termsofUse.disclaimers.title')}
    </Typography>
    <Typography variant='body1' textAlign='justify'>
      {t('termsofUse.disclaimers.text1')}
    </Typography>
    <Typography variant='body1' textAlign='justify'>
      {t('termsofUse.disclaimers.text2')}
    </Typography>
    <Typography variant='body1' textAlign='justify'>
      {t('termsofUse.disclaimers.text3')}
    </Typography>
    
    <Typography
      sx={{
        fontSize: '22px',
        fontWeight: 700,
        textAlign: 'left',
      }}
    >
      {t('termsofUse.limitationOfLiability.title')}
    </Typography>
    <Typography variant='body1' textAlign='justify'>
      {t('termsofUse.limitationOfLiability.text1')}
    </Typography>
    <Typography variant='body1' textAlign='justify'>
      {t('termsofUse.limitationOfLiability.text2')}
    </Typography>

    <Typography
      sx={{
        fontSize: '22px',
        fontWeight: 700,
        textAlign: 'left',
      }}
    >
      {t('termsofUse.indemnity.title')}
    </Typography>
    <Typography variant='body1' textAlign='justify'>
      {t('termsofUse.indemnity.text')}
    </Typography>

    <Typography
      sx={{
        fontSize: '22px',
        fontWeight: 700,
        textAlign: 'left',
      }}
    >
      {t('termsofUse.violationOfTerms.title')}
    </Typography>
    <Typography variant='body1' textAlign='justify'>
      {t('termsofUse.violationOfTerms.text1')}
    </Typography>
    <Typography variant='body1' textAlign='justify'>
      {t('termsofUse.violationOfTerms.text2')}
    </Typography>
    <Typography
      sx={{
        fontSize: '22px',
        fontWeight: 700,
        textAlign: 'left',
      }}
    >
      {t('termsofUse.governingLaw.title')}
    </Typography>
    <Typography variant='body1' textAlign='justify'>
      {t('termsofUse.governingLaw.text')}
    </Typography>
    <Typography
      sx={{
        fontSize: '22px',
        fontWeight: 700,
        textAlign: 'left',
      }}
    >
      {t('termsofUse.voidWhere.title')}
    </Typography>
    <Typography variant='body1' textAlign='justify'>
      {t('termsofUse.voidWhere.text')}
    </Typography>
    <Typography
      sx={{
        fontSize: '22px',
        fontWeight: 700,
        textAlign: 'left',
      }}
    >
      {t('termsofUse.miscellaneous.title')}
    </Typography>
    <Typography variant='body1' textAlign='justify'>
      {t('termsofUse.miscellaneous.text')}
    </Typography>
    <Typography
      sx={{
        fontSize: '22px',
        fontWeight: 700,
        textAlign: 'left',
      }}
    >
      {t('termsofUse.feedbackinf.title')}
    </Typography>
    <Typography variant='body1' textAlign='justify'>
      {t('termsofUse.feedbackinf.text')}
    </Typography>

  </Box>
  </DialogWrapper>          
    </>
  );
};

export default TermsAndConditions;
