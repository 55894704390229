import { Container, ContainerProps } from '@mui/material';

const FullHeightContainer: React.FC<ContainerProps> = (props: ContainerProps): JSX.Element => {
  return (
    <Container
      {...props}
      maxWidth={props.maxWidth}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100vh',
        padding: { xs: 0 },
        ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
      }}
    >
      {props.children}
    </Container>
  );
};

export default FullHeightContainer;
