import { useAppContext } from 'store';
import { CurrentProfile } from 'types/Profile';

interface useCurrentProfile {
  currentProfile: CurrentProfile;
}

const useCurrentProfile = (): useCurrentProfile => {
  const {
    state: { currentProfile },
  } = useAppContext();

  return { currentProfile };
};

export default useCurrentProfile;
