import { ThemeProvider } from '@mui/material/styles';
import { createMUITheme } from '../theme/muiThemeConfig';

interface Props {
  children: React.ReactNode;
}

const Theme: React.FC<Props> = ({ children }: Props) => {
  return <ThemeProvider theme={createMUITheme}>{children}</ThemeProvider>;
};

export default Theme;
