export enum BackgroundType {
  VIDEO = 'video',
  IMAGE = 'image',
}

export enum PageType {
  ENTRYPOINT = 'entrypoint',
  QRPAGE = 'QRpage',
  LOGIN = 'login',
  PROFILESELECTION = 'profileselection',
}
