export default {
  containerPX: { xs: 3, md: 8 },
  cardHeight: 230,
  titleTreatmentMaxWidth: {
    sm: '90%',
    md: 350,
  },
  titleTreatmentMaxHeight: {
    sm: 80,
    md: 180,
  },
  cardBorderRadius: 3,
  epgRowHeight: 100,
  hoursRowHeight: 60,
  oneMinuteWidth: 8,
  landing: {
    letterSpacing: '5.2px',
  },
};
