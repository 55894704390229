import Cookie from 'js-cookie';
import { setCookieExpires } from './DataTime';

const COOKIES = {
  SCH_TV_ACCESS_COOKIE: 'SCH_TV_ACCESS_COOKIE',
};

const setTokenToCookie = (name: string, access_token: string) => {
  const expires = setCookieExpires(access_token);
  Cookie.set(name, access_token, { expires });
};

const getTokenFromCookie = (name: string) => Cookie.get(name);

const removeTokenFromCookie = (name: string) => Cookie.remove(name);

export { COOKIES, setTokenToCookie, getTokenFromCookie, removeTokenFromCookie };
