import { getProfiles, profileAPIUrl } from 'api/profileApi';
import { useMemo } from 'react';
import useSWR from 'swr';
import { ProfilesData } from 'types/Profile';
import useCurrentProfile from './useCurrentProfile';

interface useProfilesOutput {
  profiles: ProfilesData | undefined;
  checkIsChild: boolean | undefined;
}

const useProfiles = (): useProfilesOutput => {
  const { data: profiles } = useSWR(profileAPIUrl.getAll, getProfiles);
  const { currentProfile } = useCurrentProfile();

  const checkIsChild = useMemo(() => {
    if (profiles && profiles.length > 0) {
      const profile = profiles.find(
        (profile) => profile.id === Number(currentProfile?.activeProfile),
      );
      return profile && profile.isKid;
    }
  }, [currentProfile?.activeProfile, profiles]);

  return { profiles, checkIsChild };
};

export default useProfiles;
