import { useState } from 'react';
import { TextField, TextFieldProps, InputAdornment, IconButton } from '@mui/material';
import { Visibility as ShowPassword, VisibilityOff as HidePassword } from '@mui/icons-material';

const PasswordInput: React.FC<TextFieldProps> = (props: TextFieldProps): JSX.Element => {
  const [isShowPassword, setShowPassword] = useState(false);

  return (
    <TextField
      {...props}
      autoComplete='on'
      type={!isShowPassword ? 'password' : 'text'}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            <IconButton
              color='inherit'
              size='small'
              onClick={() => setShowPassword((prev) => !prev)}
            >
              {!isShowPassword ? (
                <ShowPassword sx={{ color: '#727272' }} />
              ) : (
                <HidePassword sx={{ color: '#727272' }} />
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
      sx={(theme) => ({
        '& .MuiOutlinedInput-input': {
          boxShadow: 0,
        },
        '& .MuiInputAdornment-root': {
          position: 'absolute',
          right: theme.spacing(1),
        },
      })}
    />
  );
};

export default PasswordInput;
