import {
  ChannelMediaData,
  ChannelMediaPromise,
  ChannelsData,
  ChannelsPromise,
  ServicesChannels,
  ServicesChannelsPromise,
} from 'types/Channels';
import { apiClient } from './apiClient';
import ENDPOINTS from './endpoints';
import { RegionInfo } from 'types/LandingPage';

const endpoint = ENDPOINTS.CHANNEL;

const channelsApiUrl = {
  getChannels: `${endpoint}/channels`,
  getMedia: (id: number): string => `/channel/${id}/medias`,
  getServicesChannels: `${endpoint}/getall`,
  registerChannels: `${endpoint}/registerchannels`,
  getChannelBreakdown: `${endpoint}/channelbreakdown`,
};

const getSelectedChannels = (): ChannelsPromise =>
  apiClient.get<ChannelsData>(channelsApiUrl.getChannels).then((res) => res.data);

const getChannelMedia = (id: number): ChannelMediaPromise =>
  apiClient.get<Array<ChannelMediaData>>(channelsApiUrl.getMedia(id)).then((res) => res.data);

const getServicesChannels = (): ServicesChannelsPromise =>
  apiClient.get<ServicesChannels>(channelsApiUrl.getServicesChannels).then((res) => res.data);

const setSelectedServices = (
  channel: Array<{ id: string | number }>,
): Promise<{ message: string }> =>
  apiClient.post(channelsApiUrl.registerChannels, channel).then((res) => res.data);

const getChannelBreakdown = (): Promise<RegionInfo[]> =>
  apiClient.get<RegionInfo[]>(channelsApiUrl.getChannelBreakdown).then((res) => res.data);

export {
  channelsApiUrl,
  getSelectedChannels,
  getChannelMedia,
  getServicesChannels,
  setSelectedServices,
  getChannelBreakdown,
};
