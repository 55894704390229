import { SvgIcon, SvgIconProps } from '@mui/material';
import { FC } from 'react';

const AlertIcon: FC<SvgIconProps> = (props): JSX.Element => {
  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      width='22'
      height='22'
      viewBox='0 0 22 22'
      {...props}
    >
      <path
        data-name='Subtraction 2'
        d='M11 22A11 11 0 0 1 3.222 3.223a11 11 0 0 1 15.557 15.556A10.932 10.932 0 0 1 11 22zm0-7.513a.822.822 0 0 0-.822.82.821.821 0 1 0 1.643 0 .821.821 0 0 0-.821-.82zm-.181-8.619c-.009.007-.013.014-.018.014A1.278 1.278 0 0 0 9.745 7.37c.036.3.06.614.085.935l.03.381.073.875.076.911.172 2.044v.01l.017.218c.017.209.036.445.066.671a.745.745 0 0 0 .735.62.665.665 0 0 0 .189-.028.777.777 0 0 0 .576-.743c.024-.346.054-.7.08-1.009v-.005l.044-.532.193-2.274.048-.573.023-.285c.032-.4.067-.823.105-1.239a1.309 1.309 0 0 0-.722-1.362 1.644 1.644 0 0 0-.285-.089l-.036-.01c-.026-.005-.05-.012-.074-.019z'
        style={{
          fill: '#f04444',
        }}
      />
    </SvgIcon>
  );
};

export default AlertIcon;
