import { toUnixTime } from './DataTime';

const getTVProgram = (hour: number) => {
  const id = toUnixTime(`${hour}:00:00`);

  const programs = document.querySelectorAll<HTMLElement>(
    '#channels-schedule .slick-slide div .MuiBox-root',
  );

  for (let i = 0; i < programs.length; i++) {
    programs[i].style.display = 'block';
    if (
      Number(programs[i].getAttribute('id')) < Number(id) ||
      Number(programs[i].getAttribute('id')) > Number(id) + 3600
    ) {
      programs[i].style.display = 'none';
    }
  }
};

const hideOverflow = () => {
  document.body.style.overflow = 'hidden';
};

const showOverflow = () => {
  document.body.style.overflow = 'auto';
};

const openLinkInNewTab = (link: string | undefined) => window.open(link, '_blank');

export { getTVProgram, hideOverflow, showOverflow, openLinkInNewTab };
