import React from 'react';
import { Box } from '@mui/material';

import AnimatedLogo from 'assets/images/LogoAnimated.gif';

const LogoAnimated: React.FC = (): JSX.Element => {
  return (
    <Box
      component='img'
      src={AnimatedLogo}
      alt='Screen hits'
      sx={{
        width: { xs: 200, sm: 300 },
      }}
    />
  );
};

export default LogoAnimated;
