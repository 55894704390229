import { useEffect } from 'react';
import { Box } from '@mui/material';
import Navbar from 'components/Navbar';

import { Outlet } from 'react-router-dom';

import { useAppContext } from 'store';
import useSWR from 'swr';
import { getWatchList, watchListApiUrl } from 'api/watchListApi';
import { setProfiles, setWatchList } from 'store/actions';
import { getProfiles, profileAPIUrl } from 'api/profileApi';

const ProfileLayout: React.FC = (): JSX.Element => {
  const { dispatch } = useAppContext();

  const { data: watchListData } = useSWR(watchListApiUrl.getList, getWatchList);
  const { data: profiles } = useSWR(profileAPIUrl.getAll, getProfiles);

  useEffect(() => {
    profiles && dispatch(setProfiles(profiles));
  }, [dispatch, profiles]);

  useEffect(() => {
    watchListData && dispatch(setWatchList(watchListData));
  }, [dispatch, watchListData]);

  return (
    <Box sx={{ position: 'relative', pb: 3 }}>
      <Navbar />
      <Outlet />
    </Box>
  );
};

export default ProfileLayout;
