import { CssBaseline } from '@mui/material';

import SuspenseLoader from 'components/Loaders/SuspenseLoader';
import { SnackbarProvider } from 'notistack';
import { BrowserRouter } from 'react-router-dom';
import AppRoutes from 'routes/AppRoutes';
import { AppStoreProvider } from 'store';
import Theme from './Theme';
import UserAuthData from 'containers/Auth/UserAuthData';
import WrapperSWRConfig from 'components/Wrappers/SWRConfig';

import i18 from '../i18n';
import { I18nextProvider } from 'react-i18next';
import useGoogleAnalytics from 'hooks/useGoogleAnalytics';
import { useLayoutEffect } from 'react';

const App: React.FC = (): JSX.Element => {
  const { googleAnalyticsInit } = useGoogleAnalytics();

  useLayoutEffect(() => {
    googleAnalyticsInit(process.env.REACT_APP_GA4_KEY as string);
  }, [googleAnalyticsInit]);

  return (
    <I18nextProvider i18n={i18}>
      <WrapperSWRConfig>
        <AppStoreProvider>
          <BrowserRouter>
            <UserAuthData>
              <SnackbarProvider maxSnack={3}>
                <Theme>
                  <CssBaseline />
                  <SuspenseLoader>
                    <AppRoutes />
                  </SuspenseLoader>
                </Theme>
              </SnackbarProvider>
            </UserAuthData>
          </BrowserRouter>
        </AppStoreProvider>
      </WrapperSWRConfig>
    </I18nextProvider>
  );
};

export default App;
