import useAxiosRequest from 'hooks/useApiRequest';
import { Avatar, MenuItem } from '@mui/material';

import NewProfileThumb from 'assets/images/new-profile-thumb.png';
import { useAppContext } from 'store';
import { setCurrentProfile } from 'api/authApi';
import { setProfile } from 'store/actions';
import { NavLink } from 'react-router-dom';
import { appLinks } from 'routes/routes';
import { COOKIES, setTokenToCookie } from 'helpers/Cookies';

import { setStorageProfileItem } from 'helpers/LocalStorage';
import useProfiles from 'hooks/useProfiles';

interface Props {
  onClose: () => void;
}

const NavbarDropdownAccounts: React.FC<Props> = ({ onClose }: Props): JSX.Element => {
  const {
    dispatch,
    state: { currentProfile },
  } = useAppContext();

  const { requestFunc } = useAxiosRequest();
  const { profiles } = useProfiles();

  const handleMenuItemCLick = async (id: number) => {
    const res = await requestFunc(setCurrentProfile(id));
    if (res?.token) {
      onClose();
      location.reload();
      setStorageProfileItem(res);

      setTokenToCookie(COOKIES.SCH_TV_ACCESS_COOKIE, res.token);
      dispatch(setProfile(res));
    }
  };

  return (
    <>
      {profiles?.map((profile) => (
        <MenuItem
          key={profile.id}
          component={NavLink}
          to={appLinks.tvGuide.link}
          onClick={() => handleMenuItemCLick(profile.id)}
          sx={{
            display: profile.id === currentProfile.activeProfile ? 'none' : 'flex',
          }}
        >
          <Avatar
            src={NewProfileThumb}
            sx={{
              width: '24px',
              height: '24px',
              mr: 1,
            }}
          />
          {profile.name}
        </MenuItem>
      ))}
    </>
  );
};

export default NavbarDropdownAccounts;
