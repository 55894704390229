import { Container, Drawer } from '@mui/material';
import { MenuItems } from 'types/MenuItems';

import NavigationLinks from './NavigationLinks';

interface Props {
  isOpen: boolean;
  items: MenuItems | undefined;
  toggleDrawer: () => void;
}

const NavigationDrawer: React.FC<Props> = ({ isOpen, items, toggleDrawer }: Props): JSX.Element => {
  return (
    <Drawer
      anchor='left'
      open={isOpen}
      onClose={toggleDrawer}
      sx={{
        p: 4,
        visibility: isOpen ? 'visible' : 'hidden',
      }}
    >
      <Container sx={{ pl: { sm: '20px', md: '40px' } }}>
        <NavigationLinks onToggle={toggleDrawer} drawerLinks={items} />
      </Container>
    </Drawer>
  );
};

export default NavigationDrawer;
