import { Dispatch } from 'react';
import { UserData } from 'types/Auth';
import { Backgrounds } from 'types/Misc';
import { ChannelMediaData, ServiceChannel, ServicesChannels } from 'types/Channels';
import { HighlightAsset } from 'types/LandingPage';
import { EPGData } from 'types/LiveTV';
import { MediaData, WatchList } from 'types/Media';
import { CurrentProfile, ProfileData, ProfilesData } from 'types/Profile';

export interface Program {
  logo: string;
  stream: string;
  isStreaming: boolean;
  epg: EPGData;
  channelId: number;
}

export interface OtherGenresParams {
  type: number;
  genre: string;
}

export type LoginType = 'email-password' | 'phone';

export type OTPSessionID = string | undefined;

export interface AppStateContext {
  userData: UserData;
  isAuth: boolean;
  watchList: WatchList;
  childProtection: boolean;
  profiles: ProfilesData;
  selectedProfile: ProfileData;
  isProfileSelected: boolean;
  currentProfile: CurrentProfile;
  redirectURL: string;
  backgrounds: Backgrounds;
  registrationStep: number;
  selectedServices: ServicesChannels;
  selectedProgram: Program;
  isOverflowOpen: boolean;
  otherGenreType: string;
  otherGenreID: number;
  isMuted: boolean;
  isBackgroundMuted: boolean;
  isVideoEnd: boolean;
  isShowHighlightPopup: boolean;
  selectedHighlightAsset: HighlightAsset;
  location: string;
  loginType: LoginType;
  otpSessionID: OTPSessionID;
  isWithSHTVdefault: boolean;
  returnToPortalURL: string | undefined;
  isVoucherRedeemedSuccesful: boolean;
}

export interface IAppContext {
  state: AppStateContext;
  dispatch: Dispatch<AppActions>;
}

export enum AppActionsEnums {
  SET_USER_DATA = 'SET_USER_DATA',
  SET_IS_AUTH = 'SET_IS_AUTH',
  SET_CHILD_PROTECTION = 'SET_CHILD_PROTECTION',
  SET_WATCH_LIST = 'SET_WATCH_LIST',
  SET_PROFILES = 'SET_PROFILES',
  SET_SELECTED_MEDIA = 'SET_SELECTED_MEDIA',
  SET_SELECTED_PROFILE = 'SET_SELECTED_PROFILE',
  SET_PROFILE = 'SET_PROFILE',
  SET_IS_PROFILE_SELECTED = 'SET_IS_PROFILE_SELECTED',
  SET_REDIRECT_URL = 'SET_REDIRECT_URL',
  SET_BACKGROUNDS = 'SET_BACKGROUNDS',
  SET_REGISTRATION_STEP = 'SET_REGISTRATION_STEP',
  SET_SELECTED_SERVICES = 'SET_SELECTED_SERVICES',
  CLEAR_SELECTED_SERVICES = 'CLEAR_SELECTED_SERVICES',
  SET_SERVICE_TO_SELECTED = 'SET_SERVICE_TO_SELECTED',
  REMOVE_SERVICE_FROM_SELECTED = 'REMOVE_SERVICE_FROM_SELECTED',
  SET_SELECTED_EPG_PROGRAM = 'SET_SELECTED_EPG_PROGRAM',
  SET_OVERFLOW_IS_OPEN = 'SET_IS_OVERFLOW_OPEN',
  SET_OTHER_GENRE_TYPE = 'SET_OTHER_GENRE_TYPE',
  SET_OTHER_GENRE_ID = 'SET_OTHER_GENRE_ID',
  SET_IS_MUTED = 'SET_IS_MUTED',
  SET_IS_BACKGROUND_MUTED = 'SET_IS_BACKGROUND_MUTED',
  SET_IS_VIDEO_END = 'SET_IS_VIDEO_END',
  SET_IS_SHOW_HIGHLIGHT_POPUP = 'SET_IS_SHOW_HIGHLIGHT_POPUP',
  SET_SELECTED_HIGHLIGHT_ASSET = 'SET_SELECTED_HIGHLIGHT_ASSET',
  SET_LOCATION = 'SET_LOCATION',
  SET_LOGIN_TYPE = 'SET_LOGIN_TYPE',
  SET_OTP_SESSION_ID = 'SET_OTP_SESSION_ID',
  SET_IS_WITH_SHTV_DEFAULT = 'SET_IS_WITH_SHTV_DEFAULT',
  SET_RETURN_TO_PORTAL_URL = 'SET_RETURN_TO_PORTAL_URL',
  SET_IS_VOUCHER_REDEEMED_SUCCESFUL = 'SET_IS_VOUCHER_REDEEMED_SUCCESFUL',
}

interface setUserData {
  type: AppActionsEnums.SET_USER_DATA;
  payload: UserData;
}

interface setIsAuth {
  type: AppActionsEnums.SET_IS_AUTH;
  payload: boolean;
}

interface setChildProtection {
  type: AppActionsEnums.SET_CHILD_PROTECTION;
  payload: boolean;
}

interface setWatchList {
  type: AppActionsEnums.SET_WATCH_LIST;
  payload: WatchList;
}

interface setSelectedMedia {
  type: AppActionsEnums.SET_SELECTED_MEDIA;
  payload: MediaData | ChannelMediaData;
}

interface setProfiles {
  type: AppActionsEnums.SET_PROFILES;
  payload: ProfilesData;
}

interface setSelectedProfile {
  type: AppActionsEnums.SET_SELECTED_PROFILE;
  payload: ProfileData;
}

interface setProfile {
  type: AppActionsEnums.SET_PROFILE;
  payload: CurrentProfile;
}

interface setRedirectUrl {
  type: AppActionsEnums.SET_REDIRECT_URL;
  payload: string;
}

interface setBackgrounds {
  type: AppActionsEnums.SET_BACKGROUNDS;
  payload: Backgrounds;
}

interface setRegistrationStep {
  type: AppActionsEnums.SET_REGISTRATION_STEP;
  payload: number;
}

interface setSelectedServices {
  type: AppActionsEnums.SET_SELECTED_SERVICES;
  payload: ServicesChannels;
}

interface clearSelectedServices {
  type: AppActionsEnums.CLEAR_SELECTED_SERVICES;
}

interface setServiceToSelected {
  type: AppActionsEnums.SET_SERVICE_TO_SELECTED;
  payload: ServiceChannel;
}

interface removeServiceFromSelected {
  type: AppActionsEnums.REMOVE_SERVICE_FROM_SELECTED;
  payload: number;
}

interface setSelectedEpgProgram {
  type: AppActionsEnums.SET_SELECTED_EPG_PROGRAM;
  payload: Program;
}

interface setOverflowIsOpen {
  type: AppActionsEnums.SET_OVERFLOW_IS_OPEN;
  payload: boolean;
}

interface setOtherGenreTye {
  type: AppActionsEnums.SET_OTHER_GENRE_TYPE;
  payload: string;
}

interface setOtherGenreID {
  type: AppActionsEnums.SET_OTHER_GENRE_ID;
  payload: number;
}

interface setIsProfileSelected {
  type: AppActionsEnums.SET_IS_PROFILE_SELECTED;
  payload: boolean;
}
interface setIsMuted {
  type: AppActionsEnums.SET_IS_MUTED;
  payload: boolean;
}

interface setIsBackgroundMuted {
  type: AppActionsEnums.SET_IS_BACKGROUND_MUTED;
  payload: boolean;
}

interface setIsVideoEnd {
  type: AppActionsEnums.SET_IS_VIDEO_END;
}

interface setIsShowHighlightPopup {
  type: AppActionsEnums.SET_IS_SHOW_HIGHLIGHT_POPUP;
  payload: boolean;
}

interface setSelectedHighlightAsset {
  type: AppActionsEnums.SET_SELECTED_HIGHLIGHT_ASSET;
  payload: HighlightAsset;
}

interface setLocation {
  type: AppActionsEnums.SET_LOCATION;
  payload: string;
}

interface setLoginType {
  type: AppActionsEnums.SET_LOGIN_TYPE;
  payload: LoginType;
}

interface setOTPSessionID {
  type: AppActionsEnums.SET_OTP_SESSION_ID;
  payload: OTPSessionID;
}

interface setIsWithSHTVDefault {
  type: AppActionsEnums.SET_IS_WITH_SHTV_DEFAULT;
  payload: boolean;
}

interface setReturnToPortalUrl {
  type: AppActionsEnums.SET_RETURN_TO_PORTAL_URL;
  payload: string;
}

interface setIsVoucherRedeemedSuccesful {
  type: AppActionsEnums.SET_IS_VOUCHER_REDEEMED_SUCCESFUL;
  payload: boolean;
}

export type AppActions =
  | setUserData
  | setIsAuth
  | setChildProtection
  | setWatchList
  | setSelectedMedia
  | setProfiles
  | setSelectedProfile
  | setIsProfileSelected
  | setProfile
  | setRedirectUrl
  | setBackgrounds
  | setRegistrationStep
  | setSelectedServices
  | clearSelectedServices
  | setServiceToSelected
  | removeServiceFromSelected
  | setSelectedEpgProgram
  | setOverflowIsOpen
  | setOtherGenreTye
  | setOtherGenreID
  | setIsMuted
  | setIsBackgroundMuted
  | setIsVideoEnd
  | setIsShowHighlightPopup
  | setSelectedHighlightAsset
  | setLocation
  | setLoginType
  | setOTPSessionID
  | setIsWithSHTVDefault
  | setReturnToPortalUrl
  | setIsVoucherRedeemedSuccesful;
