import {
  ProfilesPromise,
  ProfilesData,
  NewProfilePromise,
  NewProfileData,
  NewProfileResponse,
  ProfileData,
} from 'types/Profile';
import { apiClient } from './apiClient';

import ENDPOINTS from './endpoints';

const endpoint = ENDPOINTS.PROFILE;

const profileAPIUrl = {
  getAll: `${endpoint}/profiles`,
  createProfile: `${endpoint}/create`,
  updateProfile: (id: string | number) => `${endpoint}/update/${id}`,
};

const getProfiles = (): ProfilesPromise =>
  apiClient.get<ProfilesData>(profileAPIUrl.getAll).then((res) => res.data);

const createProfile = (value: NewProfileData): NewProfilePromise =>
  apiClient.post<NewProfileResponse>(profileAPIUrl.createProfile, value).then((res) => res.data);

const updateProfile = (
  id: string | number,
  value: { name: string; isKid: boolean },
): Promise<ProfileData> =>
  apiClient.patch(profileAPIUrl.updateProfile(id), value).then((res) => res.data);

export { profileAPIUrl, getProfiles, createProfile, updateProfile };
