import { Dialog, DialogContent, DialogTitle } from '@mui/material';

interface Props {
  isOpen: boolean;
  title: string;
  children: React.ReactNode;
}

const DialogWrapper: React.FC<Props> = ({ isOpen, title, children }: Props): JSX.Element => {
  return (
    <Dialog
      open={isOpen}
      sx={{
        '& .MuiPaper-root': {
          opacity: '0.85',
        },
      }}
      fullWidth
      maxWidth='md'
    >
      <DialogTitle
        sx={{
          fontSize: '23px',
          fontWeight: 700,
          textAlign: 'center',
        }}
      >
        {title}
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};

export default DialogWrapper;
