import { Navigate, useLocation, useSearchParams } from 'react-router-dom';
import { useAppContext } from 'store';
import { appLinks } from './routes';

interface Props {
  children: JSX.Element;
}

const RequireAuth: React.FC<Props> = ({ children }: Props): JSX.Element => {
  const {
    state: { isAuth },
  } = useAppContext();

  const location = useLocation();

  const [params] = useSearchParams();

  const qrp = params.get('qrp');

  if (!isAuth && qrp) {
    return <Navigate to={`${appLinks.login.link}?qrp=${qrp}`} state={{ from: location }} replace />;
  }

  if (!isAuth) {
    return <Navigate to={appLinks.home.link} state={{ from: location }} replace />;
  }

  return children;
};

export default RequireAuth;
