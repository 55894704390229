import { useState } from 'react';
import { IconButton, Box, Grid } from '@mui/material';
import { Menu as MenuIcon, Close as CloseIcon } from '@mui/icons-material';
import NavigationDrawer from './NavigationDrawer';
import { MenuItems } from 'types/MenuItems';

interface Props {
  drawerItems: MenuItems | undefined;
}

const DropdownMenu: React.FC<Props> = ({ drawerItems }: Props): JSX.Element => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleToggleDrawer = () => {
    setIsDrawerOpen((prevState) => !prevState);
  };

  return (
    <>
      <Grid item xs={3}>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
          <IconButton
            edge='start'
            color='primary'
            onClick={handleToggleDrawer}
            sx={{
              py: 0,
              transitionDuration: '0.4s',
              transitionProperty: 'transform',
              transform: `${isDrawerOpen ? 'rotate(360deg)' : 'rotate(0deg)'}`,
            }}
          >
            {isDrawerOpen ? <CloseIcon /> : <MenuIcon />}
          </IconButton>
        </Box>
      </Grid>

      <NavigationDrawer
        isOpen={isDrawerOpen}
        toggleDrawer={handleToggleDrawer}
        items={drawerItems}
      />
    </>
  );
};

export default DropdownMenu;
