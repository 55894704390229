import { UserData } from 'types/Auth';
import { Backgrounds } from 'types/Misc';
import { ChannelMediaData, ServiceChannel, ServicesChannels } from 'types/Channels';
import { HighlightAsset } from 'types/LandingPage';
import { MediaData, WatchList } from 'types/Media';
import { ProfileData, CurrentProfile, ProfilesData } from 'types/Profile';
import { AppActions, AppActionsEnums, LoginType, OTPSessionID, Program } from './types';

const setUserData = (data: UserData): AppActions => ({
  type: AppActionsEnums.SET_USER_DATA,
  payload: data,
});

const setIsAuth = (isAuth: boolean): AppActions => ({
  type: AppActionsEnums.SET_IS_AUTH,
  payload: isAuth,
});

const setChildProtection = (isProtected: boolean): AppActions => ({
  type: AppActionsEnums.SET_CHILD_PROTECTION,
  payload: isProtected,
});

const setWatchList = (watchList: WatchList | []): AppActions => ({
  type: AppActionsEnums.SET_WATCH_LIST,
  payload: watchList,
});

const setSelectedMedia = (payload: MediaData | ChannelMediaData): AppActions => ({
  type: AppActionsEnums.SET_SELECTED_MEDIA,
  payload,
});

const setProfiles = (profiles: ProfilesData): AppActions => ({
  type: AppActionsEnums.SET_PROFILES,
  payload: profiles,
});

const setSelectedProfile = (payload: ProfileData): AppActions => ({
  type: AppActionsEnums.SET_SELECTED_PROFILE,
  payload,
});

const setIsProfileSelected = (payload: boolean): AppActions => ({
  type: AppActionsEnums.SET_IS_PROFILE_SELECTED,
  payload,
});

const setProfile = (payload: CurrentProfile): AppActions => ({
  type: AppActionsEnums.SET_PROFILE,
  payload,
});

const setRedirectUrl = (payload: string): AppActions => ({
  type: AppActionsEnums.SET_REDIRECT_URL,
  payload,
});

const setBackgrounds = (payload: Backgrounds): AppActions => ({
  type: AppActionsEnums.SET_BACKGROUNDS,
  payload,
});

const setRegistrationStep = (payload: number): AppActions => ({
  type: AppActionsEnums.SET_REGISTRATION_STEP,
  payload,
});

const setSelectedServices = (payload: ServicesChannels): AppActions => ({
  type: AppActionsEnums.SET_SELECTED_SERVICES,
  payload,
});

const clearSelectedServices = (): AppActions => ({
  type: AppActionsEnums.CLEAR_SELECTED_SERVICES,
});

const setServiceToSelected = (payload: ServiceChannel): AppActions => ({
  type: AppActionsEnums.SET_SERVICE_TO_SELECTED,
  payload,
});

const removeServiceFromSelected = (payload: number): AppActions => ({
  type: AppActionsEnums.REMOVE_SERVICE_FROM_SELECTED,
  payload,
});

const setSelectedEpgProgram = (payload: Program): AppActions => ({
  type: AppActionsEnums.SET_SELECTED_EPG_PROGRAM,
  payload,
});

const setOverflowIsOpen = (payload: boolean): AppActions => ({
  type: AppActionsEnums.SET_OVERFLOW_IS_OPEN,
  payload,
});

const setOtherGenreType = (payload: string): AppActions => ({
  type: AppActionsEnums.SET_OTHER_GENRE_TYPE,
  payload,
});

const setOtherGenreID = (payload: number): AppActions => ({
  type: AppActionsEnums.SET_OTHER_GENRE_ID,
  payload,
});

const setIsMuted = (payload: boolean): AppActions => ({
  type: AppActionsEnums.SET_IS_MUTED,
  payload,
});

const setIsBackgroundMuted = (payload: boolean): AppActions => ({
  type: AppActionsEnums.SET_IS_BACKGROUND_MUTED,
  payload,
});

const setIsVideoEnd = (): AppActions => ({
  type: AppActionsEnums.SET_IS_VIDEO_END,
});

const setIsShowHighlightPopup = (payload: boolean): AppActions => ({
  type: AppActionsEnums.SET_IS_SHOW_HIGHLIGHT_POPUP,
  payload,
});

const setHightlightAsset = (payload: HighlightAsset): AppActions => ({
  type: AppActionsEnums.SET_SELECTED_HIGHLIGHT_ASSET,
  payload,
});

const setLocation = (payload: string): AppActions => ({
  type: AppActionsEnums.SET_LOCATION,
  payload,
});

const setLoginType = (payload: LoginType): AppActions => ({
  type: AppActionsEnums.SET_LOGIN_TYPE,
  payload,
});

const setOTPSessionID = (payload: OTPSessionID): AppActions => ({
  type: AppActionsEnums.SET_OTP_SESSION_ID,
  payload,
});

const setIsWithSHTVDefault = (payload: boolean): AppActions => ({
  type: AppActionsEnums.SET_IS_WITH_SHTV_DEFAULT,
  payload,
});

const setReturnToPortalUrl = (payload: string): AppActions => ({
  type: AppActionsEnums.SET_RETURN_TO_PORTAL_URL,
  payload,
});

const setVoucherRedeemedSuccesful = (payload: boolean): AppActions => ({
  type: AppActionsEnums.SET_IS_VOUCHER_REDEEMED_SUCCESFUL,
  payload,
});

export {
  setUserData,
  setIsAuth,
  setChildProtection,
  setWatchList,
  setSelectedMedia,
  setProfiles,
  setSelectedProfile,
  setIsProfileSelected,
  setProfile,
  setRedirectUrl,
  setBackgrounds,
  setRegistrationStep,
  setSelectedServices,
  clearSelectedServices,
  setServiceToSelected,
  removeServiceFromSelected,
  setSelectedEpgProgram,
  setOverflowIsOpen,
  setOtherGenreType,
  setOtherGenreID,
  setIsMuted,
  setIsBackgroundMuted,
  setIsVideoEnd,
  setIsShowHighlightPopup,
  setHightlightAsset,
  setLocation,
  setLoginType,
  setOTPSessionID,
  setIsWithSHTVDefault,
  setReturnToPortalUrl,
  setVoucherRedeemedSuccesful,
};
